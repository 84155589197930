import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';

import { selectGroupBySlug } from 'store/selectors';
import { Breadcrumbs, type IBreadcrumbsProps } from 'wui/Breadcrumbs';

import classes from './ApplicationBreadcrumbs.scss';

export function ApplicationBreadcrumbs(props: Partial<IBreadcrumbsProps>) {
  const { className, ...rest } = props;

  const router = useRouter();
  const { t } = useTranslation();
  const { params } = useCurrentStateAndParams();

  const group = useSelector(selectGroupBySlug(params.slug));

  return (
    <Breadcrumbs
      aria-label="breadcrumbs"
      className={cls(classes.root, className)}
      items={[
        {
          id: 'home',
          value: t(['groups-web.breadcrumbs.home', 'Home']),
          href: router.href('home', {}, { absolute: true }),
        },
        {
          id: 'groups',
          value: t('groups-web.breadcrumbs.group-list'),
          href: router.href('groups', {}, { absolute: true }),
        },
        {
          id: 'group',
          value: group.name || t('groups-web.breadcrumbs.group-title-default'),
          href: router.href('group.discussion.feed', {}, { absolute: true }),
        },
      ]}
      {...rest}
    />
  );
}

ApplicationBreadcrumbs.displayName = 'ApplicationBreadcrumbs';
