import React from 'react';
import {
  Breadcrumbs as TPABreadcrumbs,
  BreadcrumbsProps,
} from 'wix-ui-tpa/cssVars';

export interface IBreadcrumbsProps extends BreadcrumbsProps {}

export function Breadcrumbs(props: IBreadcrumbsProps) {
  const { onClick = () => {}, ...rest } = props;

  return <TPABreadcrumbs onClick={onClick} {...rest} />;
}

Breadcrumbs.displayName = 'wui/Breadcrumbs';
